import React from "react"
import Header from "../components/header-details"
import DeveloperHeader from "../components/developer-header"
import DeveloperMall from "../components/developer-mall"
import SocialShare from '../components/socialshare';
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Footer from "../components/footer"
// do not remove this import, it loads the styles for the page
import Layout from "../components/layout";
import { checkPropTypes } from "prop-types"
import { getFullUrl } from '../../url';



export default class DevelopersPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = { malls: this.prepareMallData('') };
  }

  componentDidMount() {

  }

  prepareMallData() {

    const mallData = this.props.data.allStrapiMall.group[0];

    mallData.nodes.forEach((node) => {

        node.link = '../' + getFullUrl(node.State, node.Title, node.Mall_Number);
      
    });

    return mallData;
  }

  render() {
      const data = this.props.data;

      return (
        <>
        <Helmet>
        </Helmet>
        
        <div className="full-width" id="developerPage">
          <Header />
          <DeveloperHeader developers={this.props.pageContext.developers} path={this.props.path}/>

          <div id="railL" className="cols">

          
          <div className="col2">

              {this.props.data.allStrapiMall.group.map((group, key) => 
                  group.nodes.slice(0, Math.ceil(group.nodes.length / 2)).map((node, i) => 

                  <DeveloperMall mall={node}/>


                  )
                )
              }

          </div>
          <div className="col2">

              {this.props.data.allStrapiMall.group.map((group, key) => 
                  group.nodes.slice(Math.ceil(group.nodes.length / 2)).map((node, i) => 


                  <DeveloperMall mall={node}/>


                  )
                )
              }

          </div>
          <div className="col2-3 malldetailspageR col-m-1">

            <SocialShare />
          </div>
          </div>


          <Footer title={''} />
        </div>
      
      </>
      ) 
      


  }
}

export const query = graphql`
 
query DevelopersPage($id: String) {
  allStrapiMall(sort: {fields: Title}, filter: {Developer: {eq: $id}}) {
    group(field: Developer) {
      nodes {
        id
        Title
        Developer
        Address
        City
        State
        Zip
        id
        Reservation
        Mall_Number
      }
    }
    distinct(field: Developer)
  }  
}
`



